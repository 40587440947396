<template>
  <div class="my_present">
    <div class="present_header">提交采购询价</div>
    <div class="init_main">
      <!-- 提交采购步骤部分 start -->
      <div class="present_box_head flex_row_start_center">
        <div class="present_head_1 flex_row_start_center">
          <div class="phone_type flex_row_center_center">
            <img src="@/assets/dial-phone.png" alt="" />
          </div>
          <div class="lianxi">
            <h4>联系方式</h4>
            <p>留下您的联系方式</p>
          </div>
        </div>
        <div class="present_head_2 flex_row_start_center">
          <div class="phone_type flex_row_center_center">
            <img src="@/assets/md-assignment.png" alt="" />
          </div>
          <div class="lianxi">
            <h4>上传需求单</h4>
            <p>上传您的需求清单</p>
          </div>
        </div>
        <div class="present_head_3 flex_row_start_center">
          <div class="phone_type flex_row_center_center">
            <img src="@/assets/ze-checked.png" alt="" />
          </div>
          <div class="lianxi">
            <h4>提交需求</h4>
            <p>确认需求内容后提交</p>
          </div>
        </div>
      </div>
      <!-- 提交采购步骤部分 end -->
      <!-- 填写信息部分 start -->
      <div class="present_box_2 flex_column_start_center">
        <div class="present_box_inp flex_row_start_center">
          <p><span>*</span>单位名称：</p>
          <el-input v-model="unitName" placeholder="请输入单位名称" class="inp" />
        </div>
        <div class="present_box_inp flex_row_start_center">
          <p><span>*</span>姓名：</p>
          <el-input v-model="receiverName" placeholder="请输入您的姓名" class="inp" />
        </div>
        <div class="present_box_inp flex_row_start_center">
          <p><span>*</span>手机号：</p>
          <el-input
            v-model="receiverMobile"
            placeholder="请输入您的手机号"
            class="inp"
            maxlength="11"
          />
        </div>
        <div class="present_box_inp flex_row_start_start">
          <p style="margin-top: 10px">需求内容：</p>
          <el-input
            v-model="description"
            type="textarea"
            placeholder="请描述您的需求内容"
            style="resize: none"
            class="inp"
            maxlength="100"
            show-word-limit
          />
        </div>
        <div class="outer_14-1">
          <div class="outer14 flex-row justify-between">
            <span class="txt3">上传需求单</span>
            <div
              class="flex-row"
              :class="{ disabledUpload: fileList.length >= 3 }"
            >
              <label for="upload">
                <div class="outer15 flex-col">
                  <span class="info10">+&nbsp;添加附件</span>
                </div>
              </label>
              <!-- application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel, -->
              <input
                type="file"
                name=""
                id="upload"
                size="1"
                accept="image/png,image/jpeg,image/jpg,.xls,.xlsx"
                @change="changeFile"
                multiple
                ref="inputTab"
              />
            </div>
          </div>
          <div class="present_box_inp flex_row_start_start">
            <p style="margin-top: 10px"></p>
            <span>（支持添加xls、xlsx、jpg、png、jpeg的文件）</span>
          </div>
          <div
            class="outer16 flex-row"
            v-for="(file, fileIdx) in fileList"
            :key="fileIdx"
          >
            <img
              class="label5"
              src="@/assets/inquiry/inq_icon4.png"
              v-if="file.type == 'img'"
            />

            <img
              class="label5"
              src="@/assets/inquiry/excel.png"
              v-else-if="file.type == 'xlsx' || file.type == 'xls'"
            />

            <img class="label5" src="@/assets/inquiry/inq_icon10.png" v-else />
            <!-- <span class="word16">{{
              file.type == "img"
                ? `图片${fileIdx + 1}.${file.suffix}`
                : `文档${fileIdx + 1}.${file.suffix}`
            }}</span> -->
            <span class="word16">{{
              file.type == "img"
                ? `${file.name}.${file.suffix}`
                : `${file.name}.${file.suffix}`
            }}</span>

            <span class="txt4" @click="clearFileList(fileIdx)">删除</span>
          </div>
        </div>
      </div>
      <!-- 填写信息部分 end -->
      <div class="present_btn flex_row_center_center" @click="checkInputEvent">
        提交
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, getCurrentInstance, onMounted } from "vue";
import { inqUtils } from "@/utils/inquiry_utils.js";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
export default {
  setup(props) {
    const { proxy } = getCurrentInstance();
    const {
      uploadFile,
      fileList,
      clearFileList,
      unitList,
      formatTime,
      setFileDTO,
    } = inqUtils();
    const unitName = ref('');//单位名称
    const router = useRouter()
    const receiverName = ref('');//收货联系人姓名
    const receiverMobile = ref('');//收货联系人电话
    const description = ref('');//详细描述，
    const changeFile = (e) => {
      if (fileList.value.length >= 1) {
        ElMessage("最多上传一个附件");
        return;
      }
      uploadFile(e, () => {
        proxy.$refs.inputTab.value = "";
      });
    };

    const checkInputEvent = ()=>{
      if(!unitName.value){
        ElMessage("请输入单位名称");
      }
      if (!receiverName.value) {
        ElMessage('请输入联系人姓名')
          return false
      }
      if (!receiverMobile.value) {
        ElMessage('请输入手机号')
          return false
      }
      if (proxy.$checkPhone(receiverMobile.value) != true) {
        ElMessage.error('格式错误，请输入正确的手机号码') 
          return false
      }
      let url = 'v3/business/front/purchaseInquire/add'
      let inqDTO = {
        enterpriseName:unitName.value,
        receiverName:receiverName.value,
        receiverMobile:receiverMobile.value,
      }
      if(description.value){
        inqDTO.description = description.value
      }
      if(fileList.value.length>0){
        inqDTO.referenceFile = fileList.value.map(i => i.path).join(',')
        inqDTO.title = fileList.value.map(i => i.name).join(',')
      }
      proxy.$post(url, inqDTO, 'json').then(res => {
        if (res.state == 200) {
              ElMessage.success(res.msg)
              setTimeout(() => {
                  router.push('/member/inquiry/purchase')
              }, 500)
        } else {
              // inqDTO.receiverAddress = inqDTO.receiverAddress.split('/')
              ElMessage.error(res.msg)
        }
      })
    }

    onMounted(() => {});

    return {
      fileList,
      clearFileList,
      changeFile,
      unitName,
      receiverName,
      receiverMobile,
      description,
      checkInputEvent
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/presents.scss";
@import "../../style/base.scss";
.present_head_1 {
  background-image: url(../../assets/contact_img.png);
  background-repeat: no-repeat;
}
.present_head_2 {
  background-image: url(../../assets/upload_img.png);
  background-repeat: no-repeat;
  margin-left: -55px;
}
.present_head_3 {
  background-image: url(../../assets/red_jian.png);
  background-repeat: no-repeat;
  margin-left: -55px;
}
</style>
<style lang="scss">
input {
  height: 36px;
  outline: none;
  border: none;
}
.my_present {
  textarea {
    resize: none !important;
    height: 100px !important;
  }
}

// .my_inquiry {
//      .is-active {
//           color: $colorMain;
//      }

//      .el-tabs__active-bar {
//           background-color: $colorMain;
//      }

//      .el-tabs__item:hover {
//           color: $colorMain;
//           cursor: pointer;
//      }

//      .el-date-editor--daterange.el-input__inner {
//           width: 250px;
//      }

//      .el-date-editor .el-range-separator {
//           width: 7%;
//      }

//      .wrap6 .el-input__inner {
//           border: none;
//      }

//      .has-gutter tr th {
//           background: #F7F8FA;
//           color: #101010;
//      }
// }
</style>

